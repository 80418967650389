export default {
  "chooseAPeridodToViewTheData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a period to view the data!"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
  "numberOfGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of groups"])},
  "numberOfPaidTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of paid teachers"])},
  "numberOfStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of students"])},
  "numberOfStudentsPaying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of students paying"])},
  "numberOfTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of teachers"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "selectTheColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the Color..."])},
  "selectTheLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the Language..."])},
  "selectThePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the Period..."])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "studentsPaymentChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student payment chart"])},
  "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sum"])},
  "teachersSalaryChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher salary chart"])}
}