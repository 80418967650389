import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '../views/IndexView.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView
  },
  {
    path: '/main',
    name: 'home-index',
    component: () => import(/* webpackChunkName: 'home' */ '../views/home/IndexView.vue'),
    children: [
      {
        path: '',
        name: 'home-main',
        component: () => import(/* webpackChunkName: 'home' */ '../views/home/MainView.vue')
      },
      {
        path: 'group/:id',
        name: 'group-view',
        component: () => import(/* webpackChunkName: 'home' */ '../views/home/GroupView.vue'),
        props: true
      },
      {
        path: 'add-teacher',
        name: 'add-teacher',
        component: () => import(/* webpackChunkName: 'home' */ '../views/home/AddTeacher.vue')
      },
      {
        path: 'create-group',
        name: 'create-group',
        component: () => import(/* webpackChunkName: 'home' */ '../views/home/CreateGroup.vue')
      },
      {
        path: 'add-student',
        name: 'add-student',
        component: () => import(/* webpackChunkName: 'home' */ '../views/home/AddStudent.vue')
      },
      {
        path: 'create-time',
        name: 'create-time',
        component: () => import(/* webpackChunkName: 'home' */ '../views/home/CreateTime.vue')
      },
      {
        path: 'create-student-payment',
        name: 'create-student-payment',
        component: () => import(/* webpackChunkName: 'home' */ '../views/home/CreateStudentPayment.vue')
      },
      {
        path: 'create-teacher-salary',
        name: 'create-teacher-salary',
        component: () => import(/* webpackChunkName: 'home' */ '../views/home/CreateTeacherSalary.vue')
      },
      {
        path: 'edit-teacher',
        name: 'edit-teacher',
        component: () => import(/* webpackChunkName: 'home' */ '../views/home/EditTeacher.vue')
      },
      {
        path: 'edit-group',
        name: 'edit-group',
        component: () => import(/* webpackChunkName: 'home' */ '../views/home/EditGroup.vue')
      },
      {
        path: 'edit-student',
        name: 'edit-student',
        component: () => import(/* webpackChunkName: 'home' */ '../views/home/EditStudent.vue')
      },
      {
        path: 'edit-time',
        name: 'edit-time',
        component: () => import(/* webpackChunkName: 'home' */ '../views/home/EditTime.vue')
      },
      {
        path: 'edit-student-payment',
        name: 'edit-student-payment',
        component: () => import(/* webpackChunkName: 'home' */ '../views/home/EditStudentPayment.vue')
      },
      {
        path: 'edit-teacher-salary',
        name: 'edit-teacher-salary',
        component: () => import(/* webpackChunkName: 'home' */ '../views/home/EditTeacherSalary.vue')
      },
      {
        path: 'edit-center',
        name: 'edit-center',
        component: () => import(/* webpackChunkName: 'home' */ '../views/home/EditCenter.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: 'login' */ '../views/LoginView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
