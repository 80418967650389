import axios from "./axios";
import store from "..";

export default {
  state: {
    teachers: null,
  },
  getters: {
    getTeachers(state) {
      return state.teachers;
    },
  },
  mutations: {
    UPDATE_TEACHERS(state, payload) {
      state.teachers = payload;
    },
  },
  actions: {
    postTeacher(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(store.state.apiURL + "/teachers", payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    fetchTeachers(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            store.state.apiURL +
              "/centers/" +
              store.getters.getUser.center.id +
              "/teachers?pagination=false&order%5Bname%5D=asc"
          )
          .then((response) => {
            context.commit("UPDATE_TEACHERS", response.data["hydra:member"]);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    putTeacher(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(store.state.apiURL + "/teachers/" + payload.id, payload.object)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    deleteTeacher(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(store.state.apiURL + "/teachers/" + payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
};
