<template>
  <div class="bg-white flex rounded-lg shadow-lg">
    <span
      class="w-1 h-full rounded-lg bg-gradient-to-t"
      :class="highlight.gradientColor"
    ></span>
    <div class="p-6 flex flex-col">
      <div>
        <span
          class="flex-none inline-block p-4 bg-gradient-to-b rounded-2xl"
          :class="highlight.gradientColor"
        >
          <slot></slot>
        </span>
      </div>
      <h3 class="my-4 text-xl font-black flex-1 font-rockroll">
        {{ highlight.title }}
      </h3>
      <a
        class="group flex gap-1"
        :class="highlight.textColor"
        :href="'#' + highlight.url"
      >
        <span
          class="text-sm font-bold font-fira-code duration-300"
          :class="highlight.moreInfoButtonEffect"
        >
          Ko'proq ma'lumot olish
        </span>
        <RightIcon
          class="w-5 h-5 group-active:scale-x-125 lg:group-hover:scale-x-125 lg:group-active:scale-x-100 duration-300"
        />
      </a>
    </div>
  </div>
</template>

<script>
import RightIcon from "@/icons/RightIcon.vue";
export default {
  props: {
    highlight: {
      type: Object,
      required: true
    }
  },
  components: {
    RightIcon
  }
}
</script>
