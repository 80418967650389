import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router'
import store from './store'
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import i18n from './i18n'

createApp(App).use(i18n).use(store).use(router).use(Donut).mount('#app')
