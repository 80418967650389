export default {
  "chooseAPeridodToViewTheData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите период для просмотра данных!"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Э-почта"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домой"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
  "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
  "numberOfGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество групп"])},
  "numberOfPaidTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество оплачиваемых учителей"])},
  "numberOfStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество студентов"])},
  "numberOfStudentsPaying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество студентов, платящих"])},
  "numberOfTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество учителей"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "selectTheColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите цвет..."])},
  "selectTheLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите язык..."])},
  "selectThePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите период..."])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
  "studentsPaymentChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["График выплат учащимся"])},
  "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сум"])},
  "teachersSalaryChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["График заработной платы учителя"])}
}