export default {
  state: {
    color: localStorage.getItem("color") || null,
  },
  getters: {
    getColor(state) {
      return state.color;
    },
  },
  mutations: {
    UPDATE_COLOR(state, payload) {
      state.color = payload;
    },
  },
  actions: {
    putColor(context, payload) {
      localStorage.setItem("color", payload);
      context.commit("UPDATE_COLOR", payload);
    },
  },
};
