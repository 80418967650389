import axios from "./axios";
import store from "..";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    postCenter(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(store.state.apiURL + "/centers", payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    putCenter(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            store.state.apiURL + "/centers/" + store.getters.getUser.center.id,
            payload
          )
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
};
