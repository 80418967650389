import axios from "./axios";
import store from "..";

export default {
  state: {
    user: null,
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    UPDATE_USER(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    fetchAboutMe(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(store.state.apiURL + "/users/about_me")
          .then((response) => {
            context.commit("UPDATE_USER", response.data);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
};
