<template>
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 20.25H12.75L24 35.25H12.75L1.5 20.25Z"
      fill="url(#paint0_linear_409_252)"
    />
    <path
      d="M12.75 5.25H24L12.75 20.25H1.5L12.75 5.25Z"
      fill="url(#paint1_linear_409_252)"
    />
    <path
      d="M24 12.75H35.25L46.5 27.75H35.25L24 12.75Z"
      fill="url(#paint2_linear_409_252)"
    />
    <path
      d="M35.25 27.75H46.5L35.25 42.75H24L35.25 27.75Z"
      fill="url(#paint3_linear_409_252)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_409_252"
        x1="14.625"
        y1="20.25"
        x2="14.625"
        y2="35.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#42B983" />
        <stop offset="1" stop-color="#42B983" stop-opacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_409_252"
        x1="14.625"
        y1="5.25"
        x2="14.625"
        y2="20.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#42B983" stop-opacity="0.8" />
        <stop offset="1" stop-color="#42B983" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_409_252"
        x1="33.375"
        y1="12.75"
        x2="33.375"
        y2="27.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#42B983" stop-opacity="0.8" />
        <stop offset="1" stop-color="#42B983" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_409_252"
        x1="33.375"
        y1="27.75"
        x2="33.375"
        y2="42.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#42B983" />
        <stop offset="1" stop-color="#42B983" stop-opacity="0.8" />
      </linearGradient>
    </defs>
  </svg>
</template>
