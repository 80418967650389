import axios from "axios";
import store from "..";

export default {
  state: {
    accessToken: localStorage.getItem("accessToken") || null,
    refreshToken: localStorage.getItem("refreshToken") || null,
  },
  getters: {
    getAccessToken(state) {
      return state.accessToken;
    },
    getRefreshToken(state) {
      return state.refreshToken;
    },
  },
  mutations: {
    UPDATE_TOKENS(state, payload) {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
    },
  },
  actions: {
    postAuth(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(store.state.apiURL + "/users/auth", payload)
          .then((response) => {
            context.commit("UPDATE_TOKENS", response.data);
            localStorage.setItem("accessToken", response.data.accessToken);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    postRefreshToken(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(store.state.apiURL + "/users/auth/refreshToken", payload)
          .then((response) => {
            context.commit("UPDATE_TOKENS", response.data);
            localStorage.setItem("accessToken", response.data.accessToken);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    clearTokens(context, payload) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      context.commit("UPDATE_TOKENS", payload);
    },
  },
};
