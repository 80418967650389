import axios from "./axios";
import store from "..";

export default {
  state: {
    stPayments: null,
    timePayments: null,
  },
  getters: {
    getSTPayments(state) {
      return state.stPayments;
    },
    getTimePayments(state) {
      return state.timePayments;
    },
  },
  mutations: {
    UPDATE_ST_PAYMENTS(state, payload) {
      state.stPayments = payload;
    },
    UPDATE_TIME_PAYMENTS(state, payload) {
      state.timePayments = payload;
    },
  },
  actions: {
    postPayment(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(store.state.apiURL + "/payments", payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    fetchTimePayments(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            store.state.apiURL +
              "/centers/" +
              store.getters.getUser.center.id +
              "/times/" +
              store.getters.getCurrentTime.id +
              "/payments?pagination=false&order%5Bstudent.name%5D=asc"
          )
          .then((response) => {
            context.commit(
              "UPDATE_TIME_PAYMENTS",
              response.data["hydra:member"]
            );
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    fetchSTPayments(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            store.state.apiURL +
              "/centers/" +
              store.getters.getUser.center.id +
              "/payments?pagination=false&order%5Bstudent.name%5D=asc" +
              "&squad=" +
              payload.squad +
              "&time=" +
              store.getters.getCurrentTime.id
          )
          .then((response) => {
            context.commit("UPDATE_ST_PAYMENTS", response.data["hydra:member"]);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    fetchAnySTPayments(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            store.state.apiURL +
              "/centers/" +
              store.getters.getUser.center.id +
              "/payments?pagination=false&order%5Bstudent.name%5D=asc" +
              "&squad=" +
              payload.squad +
              "&time=" +
              payload.time
          )
          .then((response) => {
            context.commit("UPDATE_ST_PAYMENTS", response.data["hydra:member"]);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    putPayment(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(store.state.apiURL + "/payments/" + payload.id, payload.object)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    deletePayment(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(store.state.apiURL + "/payments/" + payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
};
