<template>
  <!-- top section start -->
  <div>
    <div class="absolute top-0 right-0 -z-50 overflow-hidden w-screen h-screen">
      <span
        class="absolute w-96 lg:w-192 h-96 lg:h-192 -top-48 lg:-top-72 -right-80 lg:-right-96 bg-gradient-to-b from-teal-700 to-teal-500 rounded-3xl rotate-45 lg:-rotate-45"
      ></span>
    </div>
    <div class="flex justify-end p-4 pb-0">
      <router-link
        class="text-sm lg:text-lg font-bold bg-brand text-white py-2 px-4 rounded-lg shadow-md shadow-brand/50 active:bg-teal-800 lg:active:bg-teal-700 lg:hover:bg-teal-800 duration-300 font-fira-code"
        to="/main"
      >
        ✨Kirish
      </router-link>
    </div>
    <div class="mx-4 lg:mx-12">
      <div class="flex items-center">
        <LogoIcon class="shrink-0 w-12 lg:w-16 h-12 lg:h-16" />
        <div class="font-audiowide text-2xl lg:text-4xl">
          <span class="text-vue-green">Startup</span><span>Center</span>
        </div>
      </div>
    </div>
    <div
      class="mt-10 lg:mt-16 px-6 lg:px-16 flex flex-col lg:flex-row-reverse lg:justify-between items-center gap-6 lg:gap-16"
    >
      <ProgramGoalIcon class="shrink-0 w-64 h-64 lg:w-96 lg:h-96" />
      <div>
        <h3 class="text-xl lg:text-3xl leading-normal font-rockroll">
          <span class="border-b border-red-500 font-bold">
            Siz mustaqil o'qituvchimisiz</span
          >? yoki
          <span class="border-b border-green-500 font-bold">
            sizning o'quv markazingiz bormi</span
          >? balki
          <span class="border-b border-blue-500 font-bold">
            siz o'quv markaz adminstartori</span
          >?
        </h3>
        <p class="mt-4 lg:mt-6 lg:text-lg text-neutral-700">
          Bizda siz uchun zo'r yangilik bor.
          <span class="text-teal-700 font-bold">StartupCenter</span>
          dasturi sizga o'quv markazingiz yoki repetitorlik faoliyatingizni
          hisobotini avtomatlashtiradi. O'quvchilar bazasi, ularning oylik
          to'lovlari, oylik hisobotlar tarixini boshqarishda
          <span class="text-teal-700 font-bold">StartupCenter</span>
          sizga yordam beradi.
        </p>
        <div class="mt-6 lg:mt-9 flex items-center gap-2">
          <a
            class="text-sm lg:text-lg font-bold bg-brand text-white py-2 px-8 rounded-lg shadow-md shadow-brand/50 active:bg-teal-800 lg:active:bg-teal-700 lg:hover:bg-teal-800 duration-300 font-fira-code"
            href="https://t.me/cosmostaxisupport"
            target="_blank"
          >
            Bog'lanish
          </a>
          <span
            class="text-2xs lg:text-sm font-bold text-neutral-400/70 uppercase font-rockroll"
          >
            Bepul maslahat olish uchun bu yerga bosing.
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- top section end -->

  <!-- highlight cards start -->
  <div
    class="my-6 grid grid-cols-1 lg:grid-cols-3 px-6 lg:px-16 py-10 gap-10 relative"
  >
    <span
      class="absolute w-full h-full bg-neutral-200/20 backdrop-blur -z-50"
    ></span>
    <highlight-card
      v-for="highlight in highlights"
      :key="highlight.url"
      :highlight="highlight"
    >
      <component class="w-8 h-8 stroke-white text-white" :is="highlight.icon" />
    </highlight-card>
  </div>
  <!-- highlight cards end -->

  <!-- more information start -->
  <more-information-card
    v-for="highlight in highlights"
    :key="highlight.url"
    :highlight="highlight"
  ></more-information-card>
  <!-- more information end -->

  <!-- footer start -->
  <div
    class="py-20 bg-gradient-to-t from-neutral-100 to-neutral-200 border-t-2 lg:flex lg:justify-between lg:items-center lg:px-16"
  >
    <div>
      <a class="flex justify-center items-center" href="#">
        <LogoIcon class="shrink-0 w-12 lg:w-16 h-12 lg:h-16" />
        <div class="font-audiowide text-2xl lg:text-4xl">
          <span class="text-vue-green">Startup</span><span>Center</span>
        </div>
      </a>
      <p class="text-center lg:text-left font-bold py-4">
        &copy; 2022 - Startup
      </p>
    </div>
    <div
      class="my-4 flex flex-col justify-center items-center font-fira-code space-y-2"
    >
      <a class="border-b-2 border-brand" href="tel: +998998353465"
        >+99899 835 34 65</a
      >
      <a class="border-b-2 border-brand" href="tel: +998906651164"
        >+99890 665 11 64</a
      >
    </div>
  </div>
  <!-- footer end -->
</template>

<script>
import LogoIcon from "@/icons/LogoIcon.vue";
import ProgramGoalIcon from "@/icons/ProgramGoalIcon.vue";
import RightIcon from "@/icons/RightIcon.vue";
import NoPaperAndExcelIcon from "@/icons/NoPaperAndExcelIcon.vue";
import AnyTimeIcon from "@/icons/AnyTimeIcon.vue";
import ResponsiveIcon from "@/icons/ResponsiveIcon.vue";
import DesignIcon from "@/icons/DesignIcon.vue";
import HighlightCard from "@/components/HighlightCard.vue";
import MoreInformationCard from "@/components/MoreInformationCard.vue";
export default {
  name: "IndexView",
  components: {
    LogoIcon,
    ProgramGoalIcon,
    RightIcon,
    NoPaperAndExcelIcon,
    AnyTimeIcon,
    ResponsiveIcon,
    DesignIcon,
    HighlightCard,
    MoreInformationCard,
  },
  data() {
    return {
      highlights: [
        {
          title: "Qog'oz va Exceldan voz kechish vaqti keldi.",
          url: "nopaperandexcel",
          description: "Ma'lumotlarni elektron shaklda boshqarish.",
          text: "Bugungi kunda ma'lumotlarni qog'ozda boshqarish noqulayliklarni keltirib chiqarmoqda. Siz ma'lumotlarni doim qog'ozdan qidirishingiz, tahrirlashingiz va uni doim olib yurishingiz kerak bo'ladi. Endi buning zo'r yechimi bor! Bizning dastur sizga barcha ma'lumotlarningizni to'laqonli elektron ko'rinishda yuritishga yordam beradi. Siz o'quvchilaringizni ismi, familiyasi, to'lovlari, guruhlari kabi muhim ma'lumotlarni elektron ko'rinishda yuritishingiz mumkin.",
          img: "/images/highlight1.png",
          gradientColor: "from-emerald-400 to-emerald-500",
          textColor: "text-emerald-400",
          moreInfoButtonEffect:
            "group-active:text-emerald-500 lg:group-hover:text-emerald-500 lg:group-active:text-emerald-400",
          icon: "NoPaperAndExcelIcon",
          backgroundColor: "bg-emerald-400",
        },
        {
          title: "Hisobotlarni istalgan vaqtda ko'rish.",
          url: "anytime",
          description: "24/7 qo'llab-quvvatlash",
          text: "O'quv markazingiz hisoboti, sizni goh-gohida bezovta qiladi, ammo sizda buni tekshirishda muammo bormi? Bu dastur sizning ushbu muammoingizni ham oson hal qilishi mumkin. Dastur orqali siz markazingiz ayni paytdagi holatini ko'rishingiz mumkin. Xoh tunda, xoh safarda yoki boshqa davlatda bo'lsangiz ham, endi markazingiz bilan hamnafassiz. Shunchaki sizda internet bo'lsa bas, tizimdan istalgan ma'lumotingizni tekshirishingiz mumkin.",
          img: "/images/highlight2.png",
          gradientColor: "from-indigo-400 to-indigo-500",
          textColor: "text-indigo-400",
          moreInfoButtonEffect:
            "group-active:text-indigo-500 lg:group-hover:text-indigo-500 lg:group-active:text-indigo-400",
          icon: "AnyTimeIcon",
          backgroundColor: "bg-indigo-400",
        },
        {
          title: "Turli qurilmalarda foydalanish imkoniyati.",
          url: "responsive",
          description: "Telefon, planshet va kompyuterda.",
          text: "Ma'lumotlarni faqatgina qog'oz yoki kompyuterda(sheets) ko'rish ayrim noqulayliklar keltirib chiqaradi. Ushbu dastur orqali siz bu muammolarni oson yechasiz. Hozirgi kunda sizda internet bilan ishlaydigan qurilmalar muammo emas. Android, IOS, planshet, noutbuk, Macbooklar va istalgan browser ishlaydigan qurilma orqali sizda ma'lumotlarni ko'rish, boshqarish, tahrirlash imkoniyatlari mavjud. Xullas sizda internet va qurilma bo'lsa bo'lgani. Qolgani bir zumda ekranizda namoyon bo'ladi.",
          img: "/images/highlight3.png",
          gradientColor: "from-red-400 to-red-500",
          textColor: "text-red-400",
          moreInfoButtonEffect:
            "group-active:text-red-500 lg:group-hover:text-red-500 lg:group-active:text-red-400",
          icon: "ResponsiveIcon",
          backgroundColor: "bg-red-400",
        },
        {
          title: "Zamonaviy dizayn.",
          url: "design",
          description: "Sodda va tushunarli foydalanuvchi interfeysi.",
          text: "Odatda siz o'z markazingiz hisobotini ko'rganingizda, bosh og'rig'i, yoqimsiz jadvalga qaraganday qaraysiz. Chunki hisob kitob va to'lovlarni birma-bir tekishirishingizga to'g'ri keladi. Bu esa sizni xuddi buxgalterga aylantirgandek taasurot uyg'otadi. Hisobotga qaragingniz ham kelmaydi, qanchalik kattalashsa, shunchalik yoqimsiz hislarni paydo qiladi. Endi esa sizda bizning dastur orqali zamonaviy dizaynga ega va oson boshqaruvli interfeysga ega bo'lish imkoniyati mavjud. Dastur zamonaviy dizayn va aqlli hisob qobiliyatiga ega.",
          img: "/images/highlight4.png",
          gradientColor: "from-purple-400 to-purple-500",
          textColor: "text-purple-400",
          moreInfoButtonEffect:
            "group-active:text-purple-500 lg:group-hover:text-purple-500 lg:group-active:text-purple-400",
          icon: "DesignIcon",
          backgroundColor: "bg-purple-400",
        },
      ],
    };
  },
};
</script>
