import axios from "./axios";
import store from "..";

export default {
  state: {
    timeSalaries: null,
  },
  getters: {
    getTimeSalaries(state) {
      return state.timeSalaries;
    },
  },
  mutations: {
    UPDATE_TIME_SALARIES(state, payload) {
      state.timeSalaries = payload;
    },
  },
  actions: {
    postSalary(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(store.state.apiURL + "/salaries", payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    fetchTimeSalaries(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            store.state.apiURL +
              "/centers/" +
              store.getters.getUser.center.id +
              "/times/" +
              store.getters.getCurrentTime.id +
              "/salaries?pagination=false&order%5Bteacher.name%5D=asc"
          )
          .then((response) => {
            context.commit(
              "UPDATE_TIME_SALARIES",
              response.data["hydra:member"]
            );
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    fetchAnyTimeSalaries(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            store.state.apiURL +
              "/centers/" +
              store.getters.getUser.center.id +
              "/times/" +
              payload +
              "/salaries?pagination=false&order%5Bteacher.name%5D=asc"
          )
          .then((response) => {
            context.commit(
              "UPDATE_TIME_SALARIES",
              response.data["hydra:member"]
            );
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    putSalary(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(store.state.apiURL + "/salaries/" + payload.id, payload.object)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    deleteSalary(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(store.state.apiURL + "/salaries/" + payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
};
