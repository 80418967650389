export default {
  "chooseAPeridodToViewTheData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma'lumotlarni ko'rish uchun davrni tanlang!"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pochta"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosh sahifa"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirish"])},
  "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asosiy"])},
  "numberOfGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guruhlar soni"])},
  "numberOfPaidTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maosh oluvchi o'qituvchilar soni"])},
  "numberOfStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O'quvchilar soni"])},
  "numberOfStudentsPaying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To'lov qiluvchi o'quvchilar soni"])},
  "numberOfTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O'qituvchilar soni"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochish"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parol"])},
  "selectTheColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangni tanlang..."])},
  "selectTheLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilni tanlang..."])},
  "selectThePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davrni tanlang..."])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sozlamalar"])},
  "studentsPaymentChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O'quvchilar to'lovi grafikasi"])},
  "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["so'm"])},
  "teachersSalaryChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O'qituvchilar maoshi grafikasi"])}
}