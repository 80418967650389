<template>
  <div
    :id="highlight.url"
    class="p-6 lg:px-16 flex flex-col justify-center items-center lg:flex-row gap-10 my-12 even:lg:flex-row-reverse"
  >
    <div class="lg:w-1/2">
      <h3
        class="text-center lg:text-left text-xs lg:text-sm font-bold font-fira-code uppercase tracking-widest"
        :class="highlight.textColor"
      >
        {{ highlight.title }}
      </h3>
      <p
        class="mt-5 lg:mt-8 text-center lg:text-left text-2xl lg:text-4xl font-bold font-rockroll"
      >
        {{ highlight.description }}
      </p>
      <div class="mt-6 lg:mt-10 flex justify-center lg:justify-start">
        <span
          class="w-40 h-1 bg-gradient-to-l rounded-full"
          :class="highlight.gradientColor"
        ></span>
      </div>
      <p
        class="mt-4 lg:mt-8 mb-6 lg:mb-8 text-center lg:text-left lg:text-lg text-neutral-700"
      >
        {{ highlight.text }}
      </p>
    </div>
    <div class="lg:w-1/2 relative flex justify-center items-center">
      <img
        class="rounded-lg animate-scale"
        :src="highlight.img"
        :alt="highlight.title"
      />
      <span
        class="-z-50 absolute w-4/5 h-4/5 blur-3xl"
        :class="highlight.backgroundColor"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    highlight: {
      type: Object,
      required: true,
    },
  },
};
</script>
