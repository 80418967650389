import axios from "./axios";
import store from "..";

export default {
  state: {
    squads: null,
    timeSquads: null,
  },
  getters: {
    getSquads(state) {
      return state.squads;
    },
    getTimeSquads(state) {
      return state.timeSquads;
    },
  },
  mutations: {
    UPDATE_SQUADS(state, payload) {
      state.squads = payload;
    },
    UPDATE_TIME_SQUADS(state, payload) {
      state.timeSquads = payload;
    },
  },
  actions: {
    postSquad(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(store.state.apiURL + "/squads", payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    fetchSquads(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            store.state.apiURL +
              "/centers/" +
              store.getters.getUser.center.id +
              "/squads?pagination=false&order%5Bname%5D=asc"
          )
          .then((response) => {
            context.commit("UPDATE_SQUADS", response.data["hydra:member"]);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    fetchTimeSquads(context) {
      let timeSquads = [];
      store.getters.getTimePayments.forEach((timePayment) => {
        if (timeSquads.length !== 0) {
          let isPush = false;
          timeSquads.forEach((timeSquad) => {
            if (timeSquad.id === timePayment.squad.id) {
              isPush = true;
            }
          });
          if (!isPush) {
            timeSquads.push(timePayment.squad);
          }
        } else {
          timeSquads.push(timePayment.squad);
        }
      });
      context.commit("UPDATE_TIME_SQUADS", timeSquads);
    },
    putSquad(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(store.state.apiURL + "/squads/" + payload.id, payload.object)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    deleteSquad(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(store.state.apiURL + "/squads/" + payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
};
