import { createStore } from "vuex";
import center from "./plugins/center";
import payment from "./plugins/payment";
import salary from "./plugins/salary";
import squad from "./plugins/squad";
import student from "./plugins/student";
import teacher from "./plugins/teacher";
import time from "./plugins/time";
import token from "./plugins/token";
import user from "./plugins/user";
import color from "./plugins/color";
import lang from "./plugins/lang";

export default createStore({
  state: {
    apiURL: "https://api.edu.center.webdevlx.uz/api",
    modalIsOpen: false,
    sideBarIsOpen: false,
  },
  getters: {
    getModalIsOpen(state) {
      return state.modalIsOpen;
    },
    getSideBarIsOpen(state) {
      return state.sideBarIsOpen;
    },
  },
  mutations: {
    UPDATE_MODAL_IS_OPEN(state, payload) {
      state.modalIsOpen = payload;
    },
    UPDATE_SIDE_BAR_IS_OPEN(state, payload) {
      state.sideBarIsOpen = payload;
    },
  },
  actions: {
    putModalIsOpen(context, payload) {
      context.commit("UPDATE_MODAL_IS_OPEN", payload);
    },
    putSideBarIsOpen(context, payload) {
      context.commit("UPDATE_SIDE_BAR_IS_OPEN", payload);
    },
  },
  modules: {
    token,
    user,
    center,
    teacher,
    squad,
    student,
    time,
    payment,
    salary,
    color,
    lang,
  },
});
