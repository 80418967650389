import axios from "axios";
import store from "..";

axios.interceptors.request.use(
  function (config) {
    config.headers.common["Authorization"] =
      "Bearer " + store.getters.getAccessToken;
    return config;
  },
  function (error) {
    console.log(error);
  }
);

export default axios;
