import axios from "./axios";
import store from "..";

export default {
  state: {
    times: null,
    currentTimeId: Number(localStorage.getItem("currentTimeId")) || null,
  },
  getters: {
    getTimes(state) {
      return state.times;
    },
    getCurrentTime(state) {
      if (state.times) {
        return state.times.find((time) => time.id === state.currentTimeId);
      } else {
        return null;
      }
    },
  },
  mutations: {
    UPDATE_TIMES(state, payload) {
      state.times = payload;
    },
    UPDATE_CURRENT_TIME_ID(state, payload) {
      state.currentTimeId = payload;
    },
  },
  actions: {
    postTime(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(store.state.apiURL + "/times", payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    fetchTimes(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            store.state.apiURL +
              "/centers/" +
              store.getters.getUser.center.id +
              "/times?pagination=false&order%5Byear%5D=asc&order%5Bmonth%5D=asc"
          )
          .then((response) => {
            context.commit("UPDATE_TIMES", response.data["hydra:member"]);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    putTime(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(store.state.apiURL + "/times/" + payload.id, payload.object)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    deleteTime(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(store.state.apiURL + "/times/" + payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    putCurrentTime(context, payload) {
      localStorage.setItem("currentTimeId", payload);
      context.commit("UPDATE_CURRENT_TIME_ID", payload);
    },
  },
};
