<template>
  <svg viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g class="relative" id="programgoalsvg">
      <g id="laptop">
        <path
          id="Vector"
          d="M31.0976 54.9026C31.0976 53.0718 31.9486 51.3162 33.4636 50.0216C34.9784 48.7272 37.033 48 39.1754 48H216.566C218.708 48 220.764 48.7272 222.278 50.0216C223.792 51.3162 224.644 53.0718 224.644 54.9026V170.947H31.0976V54.9026Z"
          fill="url(#paint0_linear_411_684)"
        />
        <path
          id="Vector_2"
          d="M31.0976 54.9026C31.0976 53.0718 31.9486 51.3162 33.4636 50.0216C34.9784 48.7272 37.033 48 39.1754 48H216.566C218.708 48 220.764 48.7272 222.278 50.0216C223.792 51.3162 224.644 53.0718 224.644 54.9026V170.947H31.0976V54.9026Z"
          fill="url(#paint1_linear_411_684)"
        />
        <path
          id="Vector_3"
          d="M39.0542 56.3038C39.0542 55.846 39.267 55.4072 39.6458 55.0836C40.0244 54.76 40.5382 54.5782 41.0738 54.5782H214.676C215.212 54.5782 215.726 54.76 216.104 55.0836C216.482 55.4072 216.696 55.846 216.696 56.3038V162.644C216.696 163.101 216.482 163.54 216.104 163.864C215.726 164.188 215.212 164.369 214.676 164.369H41.0818C40.5462 164.369 40.0326 164.188 39.6538 163.864C39.2752 163.54 39.0624 163.101 39.0624 162.644V56.3038H39.0542Z"
          fill="#D0D3E5"
        />
        <path
          id="Vector_4"
          d="M31.0972 170.947H224.644L239.75 228.17C241.09 231.994 236.76 234.07 232.382 234.07H24.3442C17.3649 234.07 15.3777 231.206 16.1612 228.182L31.1054 170.947H31.0972Z"
          fill="url(#paint2_linear_411_684)"
        />
        <path
          id="Vector_5"
          d="M16.0081 229.618L16 235.528C16.1212 237.97 18.4718 240 24.3444 240H232.382C236.188 240 239.944 238.434 240 235.528V229.618C239.92 232.518 236.18 234.07 232.382 234.07H24.3444C18.488 234.07 16.1454 232.056 16.0081 229.618Z"
          fill="url(#paint3_linear_411_684)"
        />
        <path
          id="Vector_6"
          d="M16.0081 229.618L16 235.528C16.1212 237.97 18.4718 240 24.3444 240H232.382C236.188 240 239.944 238.434 240 235.528V229.618C239.92 232.518 236.18 234.07 232.382 234.07H24.3444C18.488 234.07 16.1454 232.056 16.0081 229.618Z"
          fill="url(#paint4_linear_411_684)"
        />
        <path
          id="Vector_7"
          d="M16.0081 229.618L16 235.528C16.1212 237.97 18.4718 240 24.3444 240H232.382C236.188 240 239.944 238.434 240 235.528V229.618C239.92 232.518 236.18 234.07 232.382 234.07H24.3444C18.488 234.07 16.1454 232.056 16.0081 229.618Z"
          fill="url(#paint5_linear_411_684)"
        />
        <path
          id="Vector_8"
          d="M87.1744 215.04C87.2674 214.65 87.5152 214.3 87.876 214.048C88.2366 213.798 88.6878 213.66 89.1534 213.66H166.701C167.167 213.66 167.618 213.798 167.979 214.048C168.339 214.3 168.587 214.65 168.68 215.04L171.193 225.498C171.252 225.748 171.246 226.008 171.175 226.256C171.103 226.504 170.967 226.736 170.778 226.934C170.589 227.132 170.35 227.292 170.079 227.402C169.809 227.512 169.513 227.568 169.213 227.568H86.6896C86.391 227.568 86.096 227.512 85.8262 227.402C85.5562 227.294 85.318 227.134 85.1288 226.936C84.9396 226.74 84.8038 226.508 84.7314 226.26C84.6592 226.014 84.652 225.754 84.7106 225.504L87.1744 215.04Z"
          stroke="url(#paint6_linear_411_684)"
          stroke-width="0.15"
        />
        <path
          id="Vector_9"
          d="M87.1744 215.04C87.2674 214.65 87.5152 214.3 87.876 214.048C88.2366 213.798 88.6878 213.66 89.1534 213.66H166.701C167.167 213.66 167.618 213.798 167.979 214.048C168.339 214.3 168.587 214.65 168.68 215.04L171.193 225.498C171.252 225.748 171.246 226.008 171.175 226.256C171.103 226.504 170.967 226.736 170.778 226.934C170.589 227.132 170.35 227.292 170.079 227.402C169.809 227.512 169.513 227.568 169.213 227.568H86.6896C86.391 227.568 86.096 227.512 85.8262 227.402C85.5562 227.294 85.318 227.134 85.1288 226.936C84.9396 226.74 84.8038 226.508 84.7314 226.26C84.6592 226.014 84.652 225.754 84.7106 225.504L87.1744 215.04Z"
          fill="url(#paint7_linear_411_684)"
        />
        <path
          id="Vector_10"
          d="M38.1414 180.204C38.306 179.595 38.707 179.052 39.2792 178.663C39.8514 178.275 40.561 178.063 41.2918 178.064H214.918C215.652 178.065 216.364 178.279 216.938 178.672C217.51 179.064 217.908 179.612 218.068 180.224L224.336 204.176C224.62 205.26 223.286 206.282 220.758 206.282H35.3466C32.0346 206.282 31.3884 205.142 31.6872 204.058L38.1414 180.204Z"
          stroke="url(#paint8_linear_411_684)"
          stroke-width="0.15"
        />
        <path
          id="Vector_11"
          d="M38.1414 180.204C38.306 179.595 38.707 179.052 39.2792 178.663C39.8514 178.275 40.561 178.063 41.2918 178.064H214.918C215.652 178.065 216.364 178.279 216.938 178.672C217.51 179.064 217.908 179.612 218.068 180.224L224.336 204.176C224.62 205.26 223.286 206.282 220.758 206.282H35.3466C32.0346 206.282 31.3884 205.142 31.6872 204.058L38.1414 180.204Z"
          fill="url(#paint9_linear_411_684)"
        />
        <path
          id="Vector_12"
          d="M45.1612 181.301C45.1814 181.224 45.2316 181.156 45.3036 181.107C45.3756 181.058 45.465 181.032 45.557 181.032H53.5864C53.6462 181.031 53.7054 181.042 53.7598 181.063C53.8142 181.084 53.8626 181.115 53.9012 181.154C53.9398 181.193 53.9678 181.239 53.9832 181.288C53.9986 181.337 54.001 181.389 53.9904 181.439L53.0856 185.767C53.067 185.845 53.0174 185.915 52.9452 185.965C52.8732 186.016 52.7828 186.043 52.6898 186.043H44.3614C44.3008 186.044 44.2408 186.034 44.1856 186.012C44.1306 185.991 44.0818 185.959 44.043 185.919C44.0042 185.879 43.9764 185.833 43.9616 185.782C43.9468 185.732 43.9454 185.68 43.9576 185.629L45.1692 181.301H45.1612Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_13"
          d="M210.588 181.301C210.568 181.226 210.52 181.158 210.45 181.109C210.378 181.06 210.292 181.033 210.2 181.032H202.164C202.104 181.031 202.044 181.042 201.99 181.063C201.936 181.084 201.888 181.115 201.848 181.154C201.81 181.193 201.782 181.239 201.766 181.288C201.75 181.337 201.748 181.389 201.76 181.439L202.664 185.767C202.682 185.846 202.734 185.917 202.808 185.968C202.882 186.018 202.974 186.045 203.068 186.043H211.388C211.448 186.044 211.508 186.034 211.564 186.012C211.618 185.991 211.668 185.959 211.706 185.919C211.746 185.879 211.774 185.833 211.788 185.782C211.802 185.732 211.804 185.68 211.792 185.629L210.588 181.301Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_14"
          d="M43.3356 189.149C43.3542 189.071 43.4038 189.001 43.476 188.951C43.548 188.901 43.6384 188.873 43.7314 188.873H51.769C51.8286 188.872 51.888 188.883 51.9424 188.904C51.9968 188.925 52.045 188.956 52.0836 188.995C52.1224 189.034 52.1504 189.08 52.1658 189.129C52.1812 189.179 52.1836 189.23 52.1728 189.28L51.3488 193.601C51.3352 193.684 51.2866 193.76 51.2124 193.815C51.138 193.869 51.043 193.899 50.945 193.898H42.6248C42.5634 193.9 42.5024 193.889 42.4466 193.867C42.3906 193.845 42.3414 193.813 42.3024 193.772C42.2636 193.731 42.236 193.684 42.2218 193.633C42.2076 193.582 42.2074 193.528 42.2208 193.477L43.3518 189.149H43.3356Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_15"
          d="M212.414 189.149C212.396 189.071 212.346 189.001 212.274 188.951C212.202 188.901 212.112 188.873 212.018 188.873H203.988C203.928 188.872 203.87 188.883 203.816 188.904C203.76 188.925 203.712 188.956 203.674 188.995C203.636 189.034 203.608 189.08 203.592 189.129C203.576 189.179 203.574 189.23 203.584 189.28L204.4 193.601C204.414 193.684 204.462 193.76 204.538 193.815C204.612 193.869 204.706 193.899 204.804 193.898H213.14C213.202 193.898 213.262 193.887 213.316 193.865C213.372 193.842 213.42 193.81 213.458 193.769C213.496 193.729 213.522 193.682 213.536 193.631C213.55 193.58 213.55 193.528 213.536 193.477L212.414 189.149Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_16"
          d="M41.316 197.135C41.335 197.056 41.3858 196.985 41.4596 196.935C41.5334 196.884 41.6254 196.858 41.72 196.859H50.0402C50.0984 196.86 50.156 196.871 50.2088 196.892C50.2614 196.913 50.3082 196.944 50.3458 196.982C50.3834 197.02 50.411 197.064 50.4266 197.112C50.4422 197.16 50.4454 197.211 50.436 197.26L49.612 201.774C49.5966 201.856 49.5474 201.93 49.4732 201.982C49.399 202.036 49.305 202.064 49.2082 202.064H40.5972C40.5372 202.064 40.4782 202.052 40.424 202.03C40.3698 202.008 40.3222 201.976 40.2842 201.936C40.2464 201.898 40.2192 201.85 40.205 201.802C40.1906 201.752 40.1894 201.7 40.2014 201.65L41.3322 197.135H41.316Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_17"
          d="M214.434 197.136C214.414 197.058 214.366 196.987 214.294 196.937C214.22 196.887 214.13 196.859 214.038 196.859H205.718C205.658 196.859 205.6 196.869 205.546 196.89C205.492 196.911 205.444 196.941 205.406 196.979C205.368 197.017 205.34 197.062 205.324 197.111C205.308 197.159 205.304 197.21 205.314 197.26L206.138 201.774C206.152 201.856 206.202 201.93 206.276 201.984C206.35 202.036 206.444 202.064 206.542 202.064H215.16C215.22 202.064 215.28 202.052 215.334 202.03C215.388 202.008 215.436 201.976 215.474 201.938C215.512 201.898 215.538 201.852 215.552 201.802C215.568 201.752 215.568 201.7 215.556 201.65L214.434 197.136Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_18"
          d="M59.4268 181.308C59.4456 181.229 59.4964 181.158 59.5702 181.107C59.644 181.057 59.7362 181.03 59.8306 181.032H67.8602C67.919 181.031 67.9774 181.042 68.0312 181.062C68.085 181.083 68.1328 181.114 68.1712 181.152C68.2098 181.19 68.238 181.235 68.254 181.283C68.27 181.332 68.2734 181.383 68.264 181.432L67.4724 185.76C67.4568 185.842 67.4076 185.916 67.3334 185.969C67.2594 186.022 67.1652 186.051 67.0684 186.05H58.732C58.6728 186.049 58.6146 186.037 58.5616 186.015C58.5084 185.992 58.4616 185.96 58.4246 185.921C58.3876 185.881 58.3614 185.835 58.3474 185.786C58.3336 185.737 58.3326 185.685 58.3444 185.636L59.4268 181.308Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_19"
          d="M196.323 181.308C196.304 181.23 196.255 181.16 196.183 181.11C196.11 181.059 196.02 181.032 195.927 181.032H187.89C187.831 181.031 187.772 181.042 187.718 181.062C187.665 181.083 187.617 181.113 187.578 181.152C187.54 181.19 187.512 181.235 187.496 181.283C187.48 181.332 187.476 181.383 187.486 181.432L188.277 185.76C188.293 185.842 188.342 185.916 188.416 185.969C188.49 186.022 188.584 186.051 188.681 186.05H197.018C197.077 186.05 197.137 186.039 197.191 186.017C197.245 185.995 197.293 185.963 197.33 185.923C197.368 185.884 197.395 185.837 197.41 185.787C197.424 185.738 197.425 185.686 197.413 185.636L196.323 181.308Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_20"
          d="M57.884 189.156C57.9028 189.077 57.9536 189.006 58.0274 188.956C58.1012 188.905 58.1934 188.878 58.2878 188.88H66.3254C66.3834 188.88 66.441 188.89 66.4942 188.91C66.5472 188.93 66.5946 188.96 66.633 188.998C66.6712 189.035 66.6998 189.079 66.7164 189.127C66.733 189.174 66.7374 189.224 66.7292 189.274L66.0344 193.477C66.0208 193.56 65.9722 193.636 65.898 193.691C65.8236 193.745 65.7286 193.775 65.6306 193.774H57.2942C57.2336 193.775 57.1736 193.764 57.1184 193.743C57.0634 193.721 57.0146 193.69 56.9758 193.65C56.937 193.61 56.9092 193.563 56.8944 193.513C56.8796 193.463 56.8782 193.411 56.8904 193.36L57.884 189.156Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_21"
          d="M197.866 189.156C197.847 189.078 197.798 189.008 197.725 188.958C197.653 188.907 197.563 188.88 197.47 188.88H189.432C189.374 188.88 189.317 188.89 189.264 188.91C189.21 188.93 189.163 188.96 189.125 188.998C189.086 189.035 189.058 189.079 189.041 189.127C189.025 189.174 189.02 189.224 189.029 189.273L189.723 193.477C189.737 193.56 189.785 193.636 189.86 193.69C189.934 193.745 190.029 193.775 190.127 193.774H198.455C198.516 193.775 198.576 193.764 198.631 193.743C198.686 193.721 198.735 193.69 198.774 193.65C198.813 193.61 198.84 193.563 198.855 193.513C198.87 193.463 198.871 193.41 198.859 193.36L197.866 189.156Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_22"
          d="M56.1632 197.149C56.1788 197.068 56.228 196.993 56.3022 196.94C56.3764 196.887 56.4704 196.858 56.5672 196.859H64.8874C64.944 196.859 65 196.869 65.052 196.889C65.1038 196.908 65.1502 196.937 65.1882 196.972C65.2264 197.008 65.2552 197.05 65.273 197.096C65.2908 197.142 65.297 197.191 65.2914 197.239L64.742 201.698C64.732 201.784 64.685 201.862 64.6104 201.92C64.5356 201.978 64.4386 202.01 64.3382 202.008H55.711C55.652 202.01 55.5936 201.998 55.5398 201.978C55.486 201.958 55.4382 201.928 55.3998 201.888C55.3614 201.85 55.333 201.806 55.317 201.758C55.301 201.708 55.2976 201.658 55.307 201.608L56.1632 197.149Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_23"
          d="M199.594 197.149C199.579 197.068 199.53 196.993 199.455 196.94C199.381 196.887 199.287 196.858 199.191 196.859H190.87C190.814 196.859 190.758 196.869 190.706 196.889C190.654 196.908 190.607 196.937 190.569 196.972C190.531 197.008 190.502 197.05 190.485 197.096C190.467 197.142 190.461 197.191 190.466 197.239L191.008 201.698C191.018 201.784 191.065 201.862 191.139 201.92C191.214 201.978 191.311 202.01 191.412 202.008H200.038C200.098 202.01 200.156 201.998 200.21 201.978C200.264 201.958 200.312 201.928 200.35 201.888C200.388 201.85 200.416 201.806 200.432 201.758C200.448 201.708 200.452 201.658 200.442 201.608L199.594 197.149Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_24"
          d="M73.555 181.329C73.5688 181.246 73.6172 181.17 73.6916 181.115C73.7658 181.061 73.8608 181.031 73.9588 181.032H81.9964C82.053 181.032 82.109 181.042 82.1608 181.061C82.2128 181.081 82.2592 181.109 82.2972 181.145C82.3354 181.181 82.3642 181.223 82.382 181.269C82.3998 181.315 82.406 181.364 82.4002 181.412L81.9318 185.733C81.9236 185.819 81.8776 185.9 81.8026 185.959C81.7278 186.018 81.6296 186.05 81.5278 186.05H73.1996C73.1406 186.051 73.0822 186.04 73.0284 186.02C72.9748 185.999 72.927 185.969 72.8884 185.93C72.85 185.892 72.8218 185.847 72.8056 185.799C72.7896 185.75 72.7862 185.699 72.7956 185.65L73.563 181.329H73.555Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_25"
          d="M182.195 181.329C182.181 181.246 182.132 181.17 182.058 181.115C181.984 181.061 181.889 181.031 181.791 181.032H173.753C173.697 181.032 173.641 181.042 173.589 181.061C173.537 181.081 173.49 181.109 173.452 181.145C173.414 181.181 173.385 181.223 173.368 181.269C173.35 181.315 173.344 181.364 173.349 181.412L173.818 185.733C173.826 185.819 173.872 185.9 173.947 185.959C174.022 186.018 174.12 186.05 174.222 186.05H182.566C182.625 186.051 182.684 186.04 182.737 186.02C182.791 185.999 182.839 185.969 182.877 185.93C182.916 185.892 182.944 185.847 182.96 185.799C182.976 185.75 182.98 185.699 182.97 185.65L182.195 181.329Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_26"
          d="M87.6752 181.343C87.6852 181.257 87.7322 181.178 87.8068 181.121C87.8816 181.063 87.9786 181.032 88.079 181.032H96.1246C96.179 181.032 96.233 181.041 96.283 181.06C96.3332 181.078 96.3784 181.104 96.4162 181.138C96.4538 181.171 96.4832 181.211 96.5026 181.255C96.5218 181.298 96.5308 181.344 96.5286 181.391L96.3346 185.691C96.3304 185.78 96.286 185.865 96.2108 185.926C96.1356 185.988 96.0352 186.023 95.9308 186.022H87.5782C87.5216 186.023 87.4656 186.013 87.4138 185.993C87.3618 185.974 87.3154 185.945 87.2774 185.91C87.2392 185.874 87.2104 185.831 87.1926 185.786C87.175 185.74 87.1686 185.691 87.1744 185.643L87.6752 181.343Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_27"
          d="M168.083 181.343C168.072 181.257 168.026 181.178 167.951 181.121C167.876 181.063 167.779 181.032 167.679 181.032H159.633C159.579 181.032 159.525 181.041 159.475 181.06C159.425 181.078 159.379 181.104 159.342 181.138C159.304 181.171 159.274 181.211 159.255 181.255C159.236 181.298 159.227 181.344 159.229 181.391L159.423 185.691C159.427 185.78 159.472 185.865 159.547 185.926C159.622 185.988 159.723 186.023 159.827 186.022H168.171C168.228 186.023 168.284 186.013 168.336 185.993C168.388 185.974 168.434 185.945 168.472 185.91C168.51 185.874 168.539 185.831 168.557 185.786C168.575 185.74 168.581 185.691 168.575 185.643L168.083 181.343Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_28"
          d="M102.005 181.356C102.012 181.268 102.057 181.186 102.132 181.126C102.207 181.065 102.306 181.032 102.409 181.032H110.463C110.57 181.032 110.673 181.068 110.748 181.133C110.824 181.198 110.867 181.286 110.867 181.377L110.81 185.677C110.81 185.723 110.8 185.768 110.78 185.809C110.759 185.851 110.729 185.889 110.692 185.921C110.654 185.953 110.61 185.979 110.561 185.996C110.512 186.014 110.459 186.022 110.406 186.022H102.054C101.998 186.023 101.943 186.013 101.891 185.994C101.84 185.975 101.794 185.947 101.756 185.912C101.718 185.877 101.689 185.835 101.671 185.79C101.653 185.745 101.645 185.697 101.65 185.65L102.005 181.356Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_29"
          d="M153.744 181.356C153.738 181.268 153.693 181.186 153.618 181.126C153.543 181.065 153.443 181.032 153.34 181.032H145.295C145.188 181.032 145.085 181.068 145.009 181.133C144.933 181.198 144.891 181.286 144.891 181.377L144.939 185.677C144.939 185.871 145.125 186.022 145.343 186.022H153.696C153.752 186.023 153.807 186.013 153.858 185.994C153.909 185.975 153.955 185.947 153.993 185.912C154.031 185.877 154.06 185.835 154.079 185.79C154.097 185.745 154.104 185.697 154.1 185.65L153.744 181.356Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_30"
          d="M116.36 181.377C116.36 181.286 116.402 181.198 116.478 181.133C116.554 181.068 116.657 181.032 116.764 181.032H124.793C124.9 181.032 125.003 181.068 125.079 181.133C125.154 181.198 125.197 181.286 125.197 181.377V185.677C125.197 185.769 125.154 185.857 125.079 185.921C125.003 185.986 124.9 186.022 124.793 186.022H116.764C116.657 186.022 116.554 185.986 116.478 185.921C116.402 185.857 116.36 185.769 116.36 185.677V181.377Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_31"
          d="M116.36 189.184C116.36 189.138 116.37 189.094 116.391 189.052C116.411 189.01 116.441 188.972 116.478 188.94C116.516 188.908 116.56 188.882 116.609 188.865C116.658 188.848 116.711 188.839 116.764 188.839H124.793C124.846 188.839 124.899 188.848 124.948 188.865C124.997 188.882 125.041 188.908 125.079 188.94C125.116 188.972 125.146 189.01 125.166 189.052C125.187 189.094 125.197 189.138 125.197 189.184V193.484C125.197 193.576 125.154 193.663 125.079 193.728C125.003 193.793 124.9 193.829 124.793 193.829H116.764C116.657 193.829 116.554 193.793 116.478 193.728C116.402 193.663 116.36 193.576 116.36 193.484V189.184Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_32"
          d="M130.617 181.377C130.617 181.286 130.66 181.198 130.736 181.133C130.811 181.068 130.914 181.032 131.021 181.032H139.051C139.104 181.032 139.156 181.041 139.205 181.058C139.254 181.076 139.299 181.101 139.336 181.133C139.374 181.165 139.403 181.203 139.424 181.245C139.444 181.287 139.454 181.332 139.454 181.377V185.677C139.454 185.723 139.444 185.768 139.424 185.809C139.403 185.851 139.374 185.889 139.336 185.921C139.299 185.953 139.254 185.979 139.205 185.996C139.156 186.014 139.104 186.022 139.051 186.022H131.029C130.922 186.022 130.819 185.986 130.744 185.921C130.668 185.857 130.625 185.769 130.625 185.677V181.377H130.617Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_33"
          d="M130.617 189.184C130.617 189.138 130.628 189.094 130.648 189.052C130.668 189.01 130.698 188.972 130.736 188.94C130.773 188.908 130.818 188.882 130.867 188.865C130.916 188.848 130.968 188.839 131.021 188.839H139.051C139.104 188.839 139.156 188.848 139.205 188.865C139.254 188.882 139.299 188.908 139.336 188.94C139.374 188.972 139.403 189.01 139.424 189.052C139.444 189.094 139.454 189.138 139.454 189.184V193.484C139.454 193.529 139.444 193.574 139.424 193.616C139.403 193.658 139.374 193.696 139.336 193.728C139.299 193.76 139.254 193.786 139.205 193.803C139.156 193.82 139.104 193.829 139.051 193.829H131.029C130.922 193.829 130.819 193.793 130.744 193.728C130.668 193.663 130.625 193.576 130.625 193.484V189.184H130.617Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_34"
          d="M86.9724 189.198C86.9806 189.111 87.0266 189.03 87.1016 188.971C87.1764 188.912 87.2746 188.88 87.3764 188.88H95.43C95.4836 188.88 95.5368 188.889 95.5864 188.907C95.636 188.925 95.6808 188.951 95.7184 188.984C95.756 189.016 95.7856 189.055 95.8054 189.098C95.8252 189.141 95.835 189.186 95.8338 189.232L95.6724 193.45C95.6702 193.54 95.6268 193.626 95.5512 193.689C95.4756 193.752 95.3742 193.788 95.2684 193.788H86.9158C86.8592 193.788 86.8032 193.778 86.7514 193.759C86.6996 193.739 86.653 193.711 86.615 193.675C86.577 193.639 86.548 193.597 86.5304 193.551C86.5126 193.505 86.5064 193.456 86.512 193.408L86.9724 189.198Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_35"
          d="M168.777 189.198C168.769 189.111 168.723 189.03 168.648 188.971C168.573 188.912 168.475 188.88 168.373 188.88H160.328C160.274 188.88 160.221 188.889 160.171 188.907C160.122 188.925 160.077 188.951 160.039 188.984C160.002 189.016 159.972 189.055 159.952 189.098C159.932 189.141 159.923 189.186 159.924 189.232L160.085 193.45C160.088 193.54 160.131 193.626 160.207 193.689C160.282 193.752 160.384 193.788 160.489 193.788H168.834C168.89 193.788 168.946 193.778 168.998 193.759C169.05 193.739 169.097 193.711 169.135 193.675C169.173 193.639 169.202 193.597 169.219 193.551C169.237 193.505 169.243 193.456 169.238 193.408L168.777 189.198Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_36"
          d="M72.4484 189.177C72.462 189.094 72.5106 189.018 72.5848 188.963C72.6592 188.909 72.7542 188.879 72.8522 188.88H80.8978C80.953 188.88 81.0076 188.89 81.0582 188.908C81.1088 188.927 81.1544 188.954 81.1924 188.988C81.2302 189.023 81.2594 189.063 81.2782 189.107C81.297 189.152 81.305 189.199 81.3018 189.246L80.9544 193.456C80.9462 193.543 80.9 193.624 80.8252 193.683C80.7504 193.742 80.6522 193.774 80.5504 193.774H72.198C72.1398 193.774 72.0822 193.764 72.029 193.744C71.976 193.724 71.9286 193.694 71.8904 193.656C71.852 193.619 71.8234 193.575 71.8068 193.527C71.7902 193.48 71.7858 193.43 71.794 193.38L72.4484 189.177Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_37"
          d="M183.301 189.177C183.288 189.094 183.239 189.018 183.165 188.963C183.09 188.909 182.995 188.879 182.897 188.88H174.86C174.805 188.88 174.75 188.89 174.7 188.908C174.649 188.927 174.603 188.954 174.565 188.988C174.528 189.023 174.498 189.063 174.479 189.107C174.461 189.152 174.453 189.199 174.456 189.246L174.803 193.456C174.811 193.543 174.858 193.624 174.932 193.683C175.007 193.742 175.105 193.774 175.207 193.774H183.552C183.61 193.774 183.667 193.764 183.721 193.744C183.774 193.724 183.821 193.694 183.859 193.656C183.898 193.619 183.926 193.575 183.943 193.527C183.959 193.48 183.964 193.43 183.956 193.38L183.309 189.177H183.301Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_38"
          d="M101.593 189.198C101.601 189.111 101.648 189.03 101.722 188.971C101.797 188.912 101.895 188.88 101.997 188.88H110.051C110.158 188.88 110.261 188.916 110.336 188.981C110.412 189.046 110.455 189.134 110.455 189.225V193.436C110.455 193.527 110.412 193.615 110.336 193.68C110.261 193.745 110.158 193.781 110.051 193.781H101.698C101.644 193.781 101.59 193.772 101.54 193.753C101.49 193.735 101.445 193.709 101.407 193.675C101.369 193.641 101.34 193.602 101.32 193.558C101.301 193.515 101.292 193.468 101.294 193.422L101.593 189.205V189.198Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_39"
          d="M154.156 189.198C154.148 189.111 154.102 189.03 154.027 188.971C153.952 188.912 153.854 188.88 153.752 188.88H145.699C145.646 188.88 145.593 188.889 145.544 188.906C145.495 188.924 145.451 188.949 145.413 188.981C145.376 189.013 145.346 189.051 145.325 189.093C145.305 189.135 145.295 189.18 145.295 189.225V193.436C145.295 193.629 145.481 193.781 145.699 193.781H154.051C154.106 193.781 154.159 193.772 154.21 193.753C154.26 193.735 154.305 193.709 154.343 193.675C154.38 193.641 154.41 193.602 154.429 193.558C154.448 193.515 154.457 193.468 154.455 193.422L154.156 189.205V189.198Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_40"
          d="M71.0024 197.163C71.0144 197.079 71.062 197.001 71.1366 196.945C71.211 196.889 71.307 196.859 71.4062 196.859H79.7266C79.7824 196.859 79.8378 196.869 79.889 196.888C79.9402 196.907 79.9862 196.935 80.0242 196.97C80.0622 197.005 80.0912 197.046 80.1094 197.092C80.1278 197.137 80.1348 197.185 80.1304 197.232L79.783 201.692C79.775 201.778 79.7288 201.858 79.654 201.918C79.579 201.976 79.481 202.008 79.3792 202.008H70.744C70.6866 202.01 70.6298 201.998 70.5772 201.98C70.5248 201.96 70.478 201.93 70.4398 201.894C70.4014 201.856 70.3728 201.814 70.3556 201.766C70.3384 201.72 70.3332 201.67 70.34 201.622L71.0024 197.163Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_41"
          d="M184.747 197.163C184.735 197.079 184.688 197.001 184.613 196.945C184.539 196.889 184.443 196.859 184.343 196.859H176.023C175.967 196.859 175.912 196.869 175.861 196.888C175.809 196.907 175.763 196.935 175.725 196.97C175.687 197.005 175.658 197.046 175.64 197.092C175.622 197.137 175.615 197.185 175.619 197.232L175.975 201.692C175.983 201.778 176.029 201.858 176.104 201.918C176.179 201.976 176.277 202.008 176.378 202.008H185.006C185.063 202.01 185.12 201.998 185.172 201.98C185.225 201.96 185.272 201.93 185.31 201.894C185.348 201.856 185.377 201.814 185.394 201.766C185.411 201.72 185.416 201.67 185.41 201.622L184.747 197.163Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_42"
          d="M86.0676 197.177C86.0758 197.09 86.122 197.009 86.1968 196.951C86.2718 196.892 86.3698 196.859 86.4716 196.859H94.7998C94.8544 196.859 94.9082 196.869 94.9582 196.887C95.0084 196.905 95.0536 196.932 95.0914 196.965C95.129 196.999 95.1584 197.039 95.1778 197.082C95.1972 197.125 95.206 197.172 95.2038 197.218L95.0664 201.678C95.0622 201.766 95.018 201.85 94.9426 201.912C94.8674 201.974 94.767 202.008 94.6626 202.008H86.0354C85.9796 202.008 85.9242 202 85.873 201.98C85.8218 201.96 85.7756 201.934 85.7378 201.898C85.6998 201.862 85.6708 201.822 85.6524 201.776C85.6342 201.732 85.627 201.684 85.6314 201.636L86.0676 197.177Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_43"
          d="M169.682 197.177C169.674 197.09 169.628 197.009 169.553 196.951C169.478 196.892 169.38 196.859 169.278 196.859H160.95C160.895 196.859 160.841 196.869 160.791 196.887C160.741 196.905 160.696 196.932 160.658 196.965C160.62 196.999 160.591 197.039 160.572 197.082C160.552 197.125 160.544 197.172 160.546 197.218L160.683 201.678C160.687 201.766 160.732 201.85 160.807 201.912C160.882 201.974 160.983 202.008 161.087 202.008H169.722C169.778 202.008 169.833 202 169.885 201.98C169.936 201.96 169.982 201.934 170.02 201.898C170.058 201.862 170.087 201.822 170.105 201.776C170.124 201.732 170.131 201.684 170.126 201.636L169.682 197.177Z"
          fill="#D7D3E6"
        />
        <path
          id="Vector_44"
          d="M101.561 197.136C101.565 197.046 101.61 196.962 101.685 196.9C101.76 196.839 101.861 196.804 101.965 196.804H153.696C153.8 196.804 153.901 196.839 153.976 196.9C154.051 196.962 154.095 197.046 154.1 197.136L154.318 201.65C154.32 201.696 154.311 201.742 154.292 201.786C154.272 201.83 154.243 201.87 154.205 201.902C154.168 201.936 154.122 201.962 154.072 201.982C154.022 202 153.968 202.008 153.914 202.008H101.715C101.66 202.008 101.606 202 101.556 201.982C101.506 201.962 101.461 201.936 101.423 201.902C101.385 201.87 101.356 201.83 101.337 201.786C101.317 201.742 101.308 201.696 101.311 201.65L101.553 197.136H101.561Z"
          fill="#D7D3E6"
        />
      </g>
      <g class="animate-motion" id="papers">
        <path
          id="Vector_45"
          d="M163.006 104.727L100.887 114.424L86.594 22.8654L75.5976 24.8048L93.1298 124.121L165.702 111.321L164.538 104.727H163.006Z"
          fill="#A0A3BD"
        />
        <path
          id="Vector_46"
          opacity="0.1"
          d="M96.0388 121.076L163.898 110.468L163.452 104.727H163.006L100.887 114.424L86.594 22.8654L80.8534 23.874L96.0388 121.076Z"
          fill="black"
        />
        <path
          id="Vector_47"
          d="M159.069 3.87885H106.706V104.727H180.402V25.2122L159.069 3.87885Z"
          fill="#EFF0F6"
        />
        <path
          id="Vector_48"
          d="M106.705 104.727V11.4425L85.3334 14.7782L100.887 114.424L163.006 104.727H106.705ZM159.069 25.2122H180.402L159.069 3.87885V25.2122Z"
          fill="#D0D3E5"
        />
        <path
          id="Vector_49"
          opacity="0.1"
          d="M106.705 11.4424L100.887 12.354V110.545H125.731L163.006 104.727H106.705V11.4424Z"
          fill="black"
        />
        <path
          id="Vector_50"
          d="M170.453 38.303H127.787C126.991 38.303 126.332 37.6436 126.332 36.8486C126.332 36.0534 126.991 35.394 127.787 35.394H170.453C170.839 35.394 171.209 35.5472 171.482 35.82C171.755 36.0928 171.908 36.4628 171.908 36.8486C171.908 37.2342 171.755 37.6042 171.482 37.877C171.209 38.1498 170.839 38.303 170.453 38.303Z"
          fill="#FF66A6"
        />
        <path
          id="Vector_51"
          d="M118.09 38.788C119.161 38.788 120.029 37.9196 120.029 36.8486C120.029 35.7774 119.161 34.9092 118.09 34.9092C117.019 34.9092 116.15 35.7774 116.15 36.8486C116.15 37.9196 117.019 38.788 118.09 38.788Z"
          fill="#8268DF"
        />
        <path
          id="Vector_52"
          d="M170.453 48H127.787C126.991 48 126.332 47.3408 126.332 46.5456C126.332 45.7504 126.991 45.091 127.787 45.091H170.453C170.839 45.091 171.209 45.2442 171.482 45.517C171.755 45.7898 171.908 46.1598 171.908 46.5456C171.908 46.9314 171.755 47.3012 171.482 47.574C171.209 47.8468 170.839 48 170.453 48Z"
          fill="#FF66A6"
        />
        <path
          id="Vector_53"
          d="M118.09 48.485C119.161 48.485 120.029 47.6166 120.029 46.5456C120.029 45.4744 119.161 44.6062 118.09 44.6062C117.019 44.6062 116.15 45.4744 116.15 46.5456C116.15 47.6166 117.019 48.485 118.09 48.485Z"
          fill="#8268DF"
        />
        <path
          id="Vector_54"
          d="M170.453 57.697H127.787C126.991 57.697 126.332 57.0376 126.332 56.2424C126.332 55.4472 126.991 54.788 127.787 54.788H170.453C170.839 54.788 171.209 54.9412 171.482 55.214C171.755 55.4868 171.908 55.8566 171.908 56.2424C171.908 56.6282 171.755 56.9982 171.482 57.271C171.209 57.5438 170.839 57.697 170.453 57.697Z"
          fill="#FF66A6"
        />
        <path
          id="Vector_55"
          d="M118.09 58.1818C119.161 58.1818 120.029 57.3136 120.029 56.2424C120.029 55.1714 119.161 54.303 118.09 54.303C117.019 54.303 116.15 55.1714 116.15 56.2424C116.15 57.3136 117.019 58.1818 118.09 58.1818Z"
          fill="#8268DF"
        />
        <path
          id="Vector_56"
          d="M170.453 67.394H127.787C126.991 67.394 126.332 66.7346 126.332 65.9394C126.332 65.1442 126.991 64.4848 127.787 64.4848H170.453C170.839 64.4848 171.209 64.6382 171.482 64.9108C171.755 65.1836 171.908 65.5536 171.908 65.9394C171.908 66.3252 171.755 66.6952 171.482 66.968C171.209 67.2408 170.839 67.394 170.453 67.394Z"
          fill="#FF66A6"
        />
        <path
          id="Vector_57"
          d="M118.09 67.8788C119.161 67.8788 120.029 67.0104 120.029 65.9394C120.029 64.8682 119.161 64 118.09 64C117.019 64 116.15 64.8682 116.15 65.9394C116.15 67.0104 117.019 67.8788 118.09 67.8788Z"
          fill="#8268DF"
        />
        <path
          id="Vector_58"
          d="M170.453 77.091H127.787C126.991 77.091 126.332 76.4316 126.332 75.6364C126.332 74.8412 126.991 74.1818 127.787 74.1818H170.453C170.839 74.1818 171.209 74.3352 171.482 74.608C171.755 74.8806 171.908 75.2506 171.908 75.6364C171.908 76.0222 171.755 76.3922 171.482 76.665C171.209 76.9378 170.839 77.091 170.453 77.091Z"
          fill="#FF66A6"
        />
        <path
          id="Vector_59"
          d="M118.09 77.5758C119.161 77.5758 120.029 76.7076 120.029 75.6364C120.029 74.5654 119.161 73.697 118.09 73.697C117.019 73.697 116.15 74.5654 116.15 75.6364C116.15 76.7076 117.019 77.5758 118.09 77.5758Z"
          fill="#8268DF"
        />
        <path
          id="Vector_60"
          d="M170.453 86.788H127.787C126.991 86.788 126.332 86.1286 126.332 85.3334C126.332 84.5382 126.991 83.8788 127.787 83.8788H170.453C170.839 83.8788 171.209 84.032 171.482 84.3048C171.755 84.5776 171.908 84.9476 171.908 85.3334C171.908 85.7192 171.755 86.0892 171.482 86.362C171.209 86.6346 170.839 86.788 170.453 86.788Z"
          fill="#FF66A6"
        />
        <path
          id="Vector_61"
          d="M118.09 87.2728C119.161 87.2728 120.029 86.4044 120.029 85.3334C120.029 84.2622 119.161 83.394 118.09 83.394C117.019 83.394 116.15 84.2622 116.15 85.3334C116.15 86.4044 117.019 87.2728 118.09 87.2728Z"
          fill="#8268DF"
        />
      </g>
      <g class="animate-bounce-slow" id="down">
        <path
          id="Vector_62"
          d="M151.7 120C151.7 133.089 141.089 143.7 128 143.7C114.911 143.7 104.3 133.089 104.3 120C104.3 106.911 114.911 96.3 128 96.3C141.089 96.3 151.7 106.911 151.7 120Z"
          fill="white"
          stroke="#42B983"
          stroke-width="0.3"
        />
        <path
          id="Vector_63"
          d="M139.88 120.916C139.76 120.643 139.557 120.409 139.297 120.244C139.037 120.079 138.731 119.991 138.418 119.991H134.327V109.499C134.327 108.671 133.619 108 132.746 108H123.255C122.382 108 121.674 108.671 121.674 109.499V119.991H117.583C117.27 119.991 116.964 120.078 116.703 120.243C116.443 120.408 116.24 120.642 116.12 120.916C116.001 121.19 115.969 121.492 116.031 121.783C116.092 122.074 116.243 122.341 116.464 122.55L126.851 131.561C126.998 131.7 127.173 131.811 127.365 131.886C127.556 131.961 127.762 132 127.97 132C128.178 132 128.383 131.961 128.575 131.886C128.767 131.811 128.941 131.7 129.088 131.561L139.538 122.55C139.758 122.34 139.909 122.073 139.97 121.782C140.031 121.492 139.999 121.19 139.88 120.916Z"
          fill="#42B983"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_411_684"
        x1="203.698"
        y1="51.0164"
        x2="141.938"
        y2="174.494"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.411" stop-color="#6863AC" />
        <stop offset="1" stop-color="#574974" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_411_684"
        x1="127.879"
        y1="168.359"
        x2="127.879"
        y2="170.947"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_411_684"
        x1="128"
        y1="170.947"
        x2="128"
        y2="234.07"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C9C6DA" />
        <stop offset="1" stop-color="#DAD5E4" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_411_684"
        x1="128"
        y1="234.796"
        x2="128"
        y2="240"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8C8396" />
        <stop offset="1" stop-color="#998AA9" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_411_684"
        x1="242.068"
        y1="236.522"
        x2="230.71"
        y2="236.522"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B0A8B8" />
        <stop offset="1" stop-color="#B0A8B8" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_411_684"
        x1="18.4072"
        y1="234.81"
        x2="26.2346"
        y2="234.81"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#81778C" />
        <stop offset="1" stop-color="#81778C" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_411_684"
        x1="127.952"
        y1="213.66"
        x2="127.952"
        y2="227.568"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D8D4E5" />
        <stop offset="1" stop-color="#D8D4E5" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_411_684"
        x1="127.952"
        y1="213.66"
        x2="127.952"
        y2="227.568"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A9A7B8" />
        <stop offset="1" stop-color="#838191" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_411_684"
        x1="128"
        y1="178.071"
        x2="128"
        y2="206.282"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C9C7DA" />
        <stop offset="1" stop-color="#C9C7DA" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_411_684"
        x1="128"
        y1="178.071"
        x2="128"
        y2="206.282"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A9A8BD" />
        <stop offset="1" stop-color="#8A8999" />
      </linearGradient>
    </defs>
  </svg>
</template>
