export default {
  state: {
    lang: localStorage.getItem("lang") || "uz",
  },
  getters: {
    getLang(state) {
      return state.lang;
    },
  },
  mutations: {
    UPDATE_LANG(state, payload) {
      state.lang = payload;
    },
  },
  actions: {
    putLang(context, payload) {
      localStorage.setItem("lang", payload);
      context.commit("UPDATE_LANG", payload);
    },
  },
};
